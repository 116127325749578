import React from 'react'
import {TextField} from './ui'
import {ImageField} from './fields/ImageField'
import {Box, FormControl, makeStyles} from '@material-ui/core'
import {SelectField} from './fields/SelectField'
import {BooleanField} from './fields/BooleanField'
import {CounterField} from './fields/CounterField'
import {RichTextField} from './fields/RichTextField'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  }
}))

export const Field = ({field, value, onChange}) => {
  switch (field.type) {
    case 'file':
      return <ImageField label={field.title} value={value} onChange={onChange} />
    case 'select':
      return <SelectField label={field.title} value={value} onChange={onChange} options={field.options} multiple={field.multiple} />
    case 'boolean':
      return <BooleanField label={field.title} value={value} onChange={onChange} />
    case 'counter':
      return <CounterField label={field.title} value={value} onChange={onChange} />
    case 'rich':
      return <RichTextField label={field.title} value={value} onChange={onChange} />
    default:
      return <TextField type={field.type} label={field.title} value={value} onChange={onChange} />

  }
}

export const FormField = ({field, value, onChange}) => {
  const classes = useStyles()

  if (field.type === 'select' && (!field?.options?.length || (field.defaultValue && field.options.length === 1))) return null
  if (field.hidden) return null

  return (
    <Box py={2}>
      <FormControl className={classes.formControl}>
        <Field field={field} value={value !== undefined && value !== null ? value : ''} onChange={onChange} />
      </FormControl>
    </Box>
  )
}
