import React from 'react'
import {Box, Button, Typography, Container} from '@material-ui/core'
import {Link, Redirect} from 'react-router-dom'
import {CenteredBox} from '../components/ui'
import {useLocalStorageVersion} from '../utils/hooks'

export const Home = () => {
  useLocalStorageVersion('1')
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) return <Redirect to={'/dashboard'} />

  return (
    <Container>
      <CenteredBox>
        <Box>
          <Typography variant={'h1'}>TrygKalk</Typography>
          <Box display={'flex'} mt={10} justifyContent={'center'}>
            <Button component={Link} to={'/logg-inn'} variant={'contained'} color={'primary'} size={'large'}>Logg inn</Button>
          </Box>
        </Box>
      </CenteredBox>
    </Container>
  )
}
