import React from 'react'
import {makeStyles, TextField as MaterialTextField} from '@material-ui/core'

const useStyles = makeStyles(({palette, borderRadius}) => ({
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.90) !important',
    borderRadius: ({noBorderRight}) => noBorderRight ? `${borderRadius}px 0 0 ${borderRadius}px` : borderRadius,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRight: ({noBorderRight}) => noBorderRight ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: 0,
    paddingTop: ({tall}) => tall ? 16 : undefined
  },
  label: {
    color: palette.text.primary,
    fontSize: 20
  }
}))


export const TextField = ({noBorderRight, type, label, value, onChange, tall, ...props}) => {
  const classes = useStyles({noBorderRight, tall})
  const inputProps = {
    disableUnderline: true,
    className: classes.input,
  }

  return (
    <MaterialTextField
      variant={'filled'}
      label={label}
      InputLabelProps={{
        className: classes.label
      }}
      value={value}
      onChange={onChange}
      InputProps={inputProps}
      type={type}
      color={'secondary'}
      {...props}
    />
  )
}
