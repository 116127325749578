import {Box, ListItem, ListItemIcon, ListItemText, makeStyles} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(({palette}) => ({
  listItem: {
    borderBottom: '1px solid #ddd',
    position: 'relative',
    '&:hover': {
      background: '#ddd'
    }
  },
  activeListItemContainer: {
    background: palette.primary.main
  },
  listItemIcon: {
    minWidth: 40
  },
  listItemText: {
    margin: 0,
    color: palette.text.primary
  }
}))

export const MenuListItem = ({item, active, onClick}) => {
  const classes = useStyles()

  return (
    <ListItem button className={classes.listItem} onClick={onClick} style={item.style}>
      {active && <Box className={classes.activeListItemContainer} position={'absolute'} height={'100%'} width={4} left={0} />}
      {item.icon && <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>}
      <ListItemText primary={item.title} primaryTypographyProps={{className: classes.listItemText}} />
    </ListItem>
  )
}
