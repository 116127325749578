import React from 'react'
import {SubmitForm} from '../../components/SubmitForm'
import {useUserFields} from './User'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

export const CreateUser = () => {
  const userFields = useUserFields()
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)

  return (
    <SubmitForm
      fields={userFields}
      title={'Opprett bruker'}
      apiSubmitPath={'/users'}
      previousPath={previousPath}
    />
  )
}
