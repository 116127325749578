import React from 'react'
import {Select, MenuItem, makeStyles} from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'

const useStyles = makeStyles(({borderRadius}) => ({
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.90) !important',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: 0,
    borderRadius
  },
  label: {
    zIndex: 1,
    top: 8,
    left: 12
  }
}))

export const SelectField = ({label, value, onChange, options, multiple}) => {
  const classes = useStyles()
  const inputProps = {
    className: classes.input
  }

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select
        disableUnderline
        variant={'filled'}
        inputProps={inputProps}
        value={!value && multiple ? [] : value}
        onChange={onChange}
        multiple={multiple}
      >
        <MenuItem value={null} disabled>{label}</MenuItem>
        {options.map(option => {
          return (
            <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
          )
        })}
      </Select>
    </>
  )
}
