import React, {useState} from 'react'
import {makeStyles, List, Box, Typography, AppBar, Divider, IconButton, Toolbar} from '@material-ui/core'
import PowerIcon from '@material-ui/icons/PowerSettingsNew'
import {Link, useRouteMatch} from 'react-router-dom'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import {useCurrentUser, useIsAuthorized} from '../utils/authenticationUtils'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import AddIcon from '@material-ui/icons/Add'
import {MenuListItem} from './ui/menu/MenuListItem'
import {drawerWidth, MenuDrawer} from './ui/menu/MenuDrawer'

const useStyles = makeStyles(({breakpoints, palette, mixins, spacing}) => ({
  appBar: {
    background: 'white',
    borderBottom: '1px solid #ddd',
    [breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: spacing(2),
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: mixins.toolbar,
  list: {
    height: '100vh',
    padding: 0,
  }
}))

const AppMenuListItem = ({item, onClick}) => {
  const isAuthorized = useIsAuthorized(item.role)
  const active = useRouteMatch({path: item.to, exact: true})
  if (!isAuthorized) return null

  return (
    <Link to={item.to}>
      <MenuListItem item={item} active={active} onClick={onClick} />
    </Link>
  )
}

export const ResponsiveAppMenu = () => {
  const classes = useStyles()
  const currentUser = useCurrentUser()
  const [mobileOpen, setMobileOpen] = useState(false)

  const toggleDrawer = () => setMobileOpen(prevMobileOpen => !prevMobileOpen)
  const closeDrawer = () => setMobileOpen(false)

  const items = [{
    title: 'Startside',
    icon: <HomeIcon />,
    to: '/dashboard',
  }, {
    title: 'Organisasjoner',
    icon: <BusinessIcon />,
    to: '/organisasjoner',
    role: 'admin'
  }, {
    title: 'Brukere',
    icon: <PeopleIcon />,
    to: '/brukere',
    role: 'owner'
  }, {
    title: 'Soner',
    icon: <ViewQuiltIcon />,
    to: '/soner',
    role: 'owner'
  }, {
    title: 'Prisfelter',
    icon: <FormatListBulletedIcon />,
    to: '/prisfelter',
    role: 'owner'
  }, {
    title: 'Anbud',
    icon: <LocalOfferIcon />,
    to: '/anbud',
    role: 'owner'
  }, {
    title: 'Opprett anbud',
    icon: <AddIcon />,
    to: '/opprett-anbud'
  }, {
    title: 'Min organisasjon',
    icon: <BusinessIcon />,
    to: `/organisasjoner/${currentUser.organizationId}`,
    role: 'owner'
  }, {
    title: 'Min bruker',
    icon: <PersonIcon />,
    to: `/brukere/${currentUser.id}`,
  }, {
    title: 'Logg ut',
    icon: <PowerIcon />,
    to: '/logg-ut',
    style: {position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid #ddd'}
  }]

  const drawerContent = (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} className={classes.toolbar} component={Link} to={'/'} onClick={closeDrawer}>
        <Typography variant={'h3'} style={{margin: 0}} color={'textPrimary'}>TrygKalk</Typography>
      </Box>
      <Divider />
      <List className={classes.list}>
        {items.map((item) => <AppMenuListItem key={item.title} item={item} onClick={closeDrawer} />)}
      </List>
    </>
  )

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MenuDrawer content={drawerContent} mobileOpen={mobileOpen} toggleDrawer={toggleDrawer} />
    </>
  )
}
