import {useLocation, useParams} from 'react-router-dom'
import React from 'react'
import {SubmitForm} from '../../components/SubmitForm'
import {useOrganizationOptions} from '../../utils/hooks'
import {useCurrentUser} from '../../utils/authenticationUtils'
import queryString from 'query-string'

export const useZoneFields =  () => {
  const organizationOptions = useOrganizationOptions()
  const currentUser = useCurrentUser()

  return {
    name: {
      title: 'Navn',
        type: 'text',
        required: true,
        minLength: 3,
        role: 'owner'
    },
    organizationId: {
      title: 'Organisasjon',
        options: organizationOptions,
        defaultValue: currentUser.organizationId,
        type: 'select',
        role: 'owner'
    },
    active: {
      title: 'Aktiv',
        defaultValue: true,
        type: 'boolean'
    }
  }
}

export const Zone = () => {
  const {id} = useParams()
  const zoneFields = useZoneFields()
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)

  return (
    <SubmitForm
      fields={zoneFields}
      title={'Sone'}
      submitText={'Oppdater sone'}
      apiSubmitPath={`/zones/${id}`}
      apiFetchPath={`/zones/${id}`}
      previousPath={previousPath}
      dataKey={'zone'}
      apiRequestType={'put'}
    />
  )
}
