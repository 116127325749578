import {FormField} from './FormField'
import {Box, Button, Typography} from '@material-ui/core'
import React from 'react'
import {Loader} from './ui'
import {useSnackbar} from 'notistack'
import {replaceAll} from '../utils/stringUtils'

export const Form = ({fields, formData, setFormData, loading, onSubmit, title, submitText}) => {
  const {enqueueSnackbar} = useSnackbar()

  const isValid = () => {
    let valid = true

    Object.keys(fields).forEach(key => {
      const field = fields[key]
      const value = formData[key]

      if (field.required && (value === '' || value === undefined || value === null)) {
        enqueueSnackbar(`${field.title} mangler`, {variant: 'error'})
        valid = false
      } else if (field.minLength && field.minLength > value.length) {
        enqueueSnackbar(`${field.title} må inneholde minst ${field.minLength} tegn`, {variant: 'error'})
        valid = false
      } else if (field.maxLength && field.maxLength < value.length) {
        enqueueSnackbar(`${field.title} må innholde under ${field.maxLength} tegn`, {variant: 'error'})
        valid = false
      } else if (field.type === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
        enqueueSnackbar('Ugyldig e-post', {variant: 'error'})
        valid = false
      }
    })

    return valid
  }


  const handleSubmit = (event) => {
    event.preventDefault()

    if (!isValid()) return

    onSubmit(event)
  }

  const getNewValue = (event, type) => {
    switch(type) {
      case 'boolean':
        return event.target.checked
      case 'number':
      case 'counter':
        return parseInt(event.target.value) || 0
      default:
        return event.target.value
    }
  }

  const handleChange = (key, type) => (event) => {
    event.persist()
    const newValue = getNewValue(event, type)

    setFormData(prevFormData => {
      const field = fields[key]
      const processedNewValue = field.stripSpaces ? replaceAll(newValue, ' ', '') : newValue
      return {
        ...prevFormData,
        [key]: processedNewValue
      }
    })
  }

  return (
    <>
      <Box display={'flex'} justifyContent={'center'}>
        <Box maxWidth={350} width={'100%'}>
          <Typography variant={'h1'}>{title}</Typography>
          {!loading && <form onSubmit={handleSubmit} noValidate>
            {Object.keys(fields).map(key => {
              const field = fields[key]
              return <FormField key={key} field={field} value={formData[key]} onChange={handleChange(key, field.type)} />
            })}
            <Box py={2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} disabled={loading}>
              <Button type={'submit'} variant={'contained'} color={'primary'} size={'large'} disabled={loading}>
                {submitText || title}
              </Button>
            </Box>
          </form>}
        </Box>
      </Box>
      {loading && <Loader />}
    </>
  )
}
