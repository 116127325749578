import React from 'react'
import {Container} from '@material-ui/core'
import {CenteredBox} from '../../components/ui'
import {SubmitForm} from '../../components/SubmitForm'

const fields = {
  username: {
    title: 'E-post',
    type: 'email',
    required: true,
    minLength: 3
  },
  password: {
    title: 'Passord',
    key: 'password',
    type: 'password',
    required: true,
    minLength: 3
  }
}

export const Login = () => {
  const handleCompleted = (response) => {
    localStorage.setItem('accessToken', response.data.accessToken)
    localStorage.removeItem('offer')
    localStorage.removeItem('confirmOffer')
  }

  return (
    <Container style={{maxWidth: 900}}>
      <CenteredBox>
        <SubmitForm
          fields={fields}
          title={'Logg inn'}
          apiSubmitPath={`/users/login`}
          onCompleted={handleCompleted}
          nextPath={'/dashboard'}
        />
      </CenteredBox>
    </Container>
  )
}
