import RichTextEditor from 'react-rte'
import React, {useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export const RichTextField = ({label, value, onChange}) => {
  const [editorValue, setEditorValue] = useState(RichTextEditor.createValueFromString(value, 'html'))
  const handleChange = (newValue) => {
    setEditorValue(newValue)
    onChange({
      persist: () => {
      },
      target: {value: newValue.toString('html')}
    })
  }

  // TODO: This is a workaround because the form renders before the data is fetched
  useEffect(() => {
    if (value === editorValue.toString('html')) return

    setEditorValue(RichTextEditor.createValueFromString(value, 'html'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Box>
      <Typography variant={'body1'}>{label}</Typography>
      <RichTextEditor value={editorValue} onChange={handleChange} />
    </Box>
  )
}