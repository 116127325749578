import React from 'react'
import {useSnackbar} from 'notistack'
import {Redirect} from 'react-router-dom'

export const LogOut = () => {
  const {enqueueSnackbar} = useSnackbar()
  localStorage.removeItem('accessToken')
  localStorage.removeItem('offer')
  localStorage.removeItem('confirmOffer')
  enqueueSnackbar('Du er nå logget ut', {variant: 'success'})

  return <Redirect to={'/'} />
}
