import React from 'react'
import {dateFormat} from '../../utils/dateUtils'
import {OrganizationLink, UserLink} from '../../components/Links'
import {FetchAndCreate} from '../../components/FetchAndCreate'
import Box from '@material-ui/core/Box'
import {currencyFormat} from '../../utils/numberUtils'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'

const columns = [{
  title: 'Kunde',
  key: 'customer',
  sortable: true
}, {
  title: 'Pris',
  key: 'price',
  sortable: true
}, {
  title: 'Detaljer',
  key: 'details',
  sortable: false
}, {
  title: 'Organisasjon',
  key: 'organization',
  sortable: true,
  role: 'admin'
}, {
  title: 'Opprettet',
  key: 'createdAt',
  sortable: true
}, {
  title: 'Opprettet av',
  key: 'createdBy',
  sortable: true
}]

export const Customer = ({customerName, customerEmail, customerAddress, customerPhoneNumber}) => {
  return (
    <>
      {customerName && <Box>{customerName}</Box>}
      {customerEmail && <Box><a href={`mailto:${customerEmail}`}>{customerEmail}</a></Box>}
      {customerPhoneNumber && <Box>{customerPhoneNumber}</Box>}
      {customerAddress && <Box>{customerAddress}</Box>}
    </>
  )
}

const createRows = (offers) => {
  return offers.map((offer) => {
    return {
      customer: {
        renderValue: <Customer {...offer} />,
        sortValue: offer.customerName,
        searchValue: [offer.customerAddress, offer.customerEmail, offer.customerName, offer.customerPhoneNumber],
      },
      price: {
        renderValue: `${currencyFormat(offer.price - offer.discount)} ${offer.discount ? `(${currencyFormat(offer.price)} Inkludert ${currencyFormat(offer.discount)} rabatt)` : ''}`,
        sortValue: offer.price,
        searchValue: 'renderValue',
      },
      organization: {
        renderValue: <OrganizationLink id={offer.organizationId} name={offer.organizationName} />,
        sortValue: offer.organizationName
      },
      createdAt: {
        renderValue: dateFormat.format(Date.parse(offer.createdAt)),
        searchValue: 'renderValue',
        sortValue: offer.createdAt
      },
      createdBy: {
        renderValue: <UserLink id={offer.createdById} name={offer.createdByName} />,
        sortValue: offer.createdByName
      },
      details: {
        renderValue: <Button component={Link} variant={'outlined'} size={'small'} to={`/anbud/${offer.id}?returner=/anbud`}>Vis detaljer</Button>
      }
    }
  })
}

export const Offers = () => {
  return (
    <FetchAndCreate
      title={'Anbud'}
      createButtonText={'Opprett anbud'}
      apiPath={'/offers'}
      createPath={'/opprett-anbud?returner=/anbud'}
      dataKey={'offers'}
      columns={columns}
      createRows={createRows}
    />
  )
}
