import React, {useState, useEffect} from 'react'
import {Typography, Box, IconButton, makeStyles, Card} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {Link} from 'react-router-dom'
import {useFetch} from '../utils/apiUtils'
import {Loader, TextField} from '../components/ui'
import {currencyFormat} from '../utils/numberUtils'
import {dateFormat} from '../utils/dateUtils'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Pagination from '@material-ui/lab/Pagination'
import Mark from 'mark.js';

const useStyles = makeStyles(({palette}) => ({
  createOfferButton: {
    background: palette.primary.main,
    color: 'white',
    '&:hover': {
      background: palette.primary.dark
    }
  }
}))

function filterOffers(offers, filter) {
  if (!offers || !filter) {
    return offers;
  }
  const lowerCaseFilter = String(filter).toLowerCase();
  return offers.filter(offer => {
    const searchableOffer = [
      offer.customerName,
      dateFormat.format(Date.parse(offer.createdAt)),
      offer.id,
      currencyFormat(offer.price),
      !offer.discount ? '' : currencyFormat(offer.price - offer.discount),
      !offer.discount ? '' : currencyFormat(offer.discount),
      offer.customerEmail,
      offer.customerPhoneNumber,
      offer.customerAddress
    ].join(', ').toLowerCase();
    return searchableOffer.includes(lowerCaseFilter);
  })
}

export const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const data = useFetch('/offers', setLoading)
  const offers = data && data.offers
  const classes = useStyles()


  const markContainer = useState({
    markInstancenew: new Mark(document.querySelector("#search-node"))
  });

  const [filter, setFilter] = useState('');

  useEffect(() => {
    markContainer.markInstance = new Mark(document.querySelector("#search-node"))
    markContainer.markInstance.unmark({
      done: () => {
        markContainer.markInstance.mark(filter);
      }
    });
  });

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} my={4}>
        {offers && (
          <Box width={'100%'} maxWidth={400}>
            <Typography variant={'h1'}>Mine anbud</Typography>
            <TextField
              label="Søk i anbud"
              value={filter}
              fullWidth
              onChange={e => setFilter(e.target.value ?? '')}
            />
            <div id="search-node">
              <Offers offers={filterOffers(offers, filter)} />
            </div>
          </Box>
        )}

        <Box position={'fixed'} bottom={20} right={20}>
          <IconButton className={classes.createOfferButton} component={Link} to={'/opprett-anbud'}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      {loading && <Loader />}
    </>
  )
}

const offersPerPage = 20
const Offers = ({offers}) => {
  const [page, setPage] = useState(1)
  const handlePageChange = (_, value) => {
    setPage(value)
    window.scrollTo({top: 0})
  }
  if (!offers.length) return <Typography variant={'h4'}>Listen er tom</Typography>

  const totalPages = Math.ceil(offers.length / offersPerPage)
  return (
    <>
      {offers.slice((page - 1) * offersPerPage, (page - 1) * offersPerPage + offersPerPage).map((offer, i) => {
        return (
          <Card component={Box} width={'100%'} my={2} key={offer.id}>
            <Box p={5}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography variant={'h3'} style={{marginBottom: 0}}>{offer.customerName}</Typography>
                <Typography variant={'body2'}>{dateFormat.format(Date.parse(offer.createdAt))}</Typography>
              </Box>
              <Divider style={{margin: '16px 0'}} />
              <Typography variant={'body1'}>ID: {offer.id}</Typography>
              <Typography variant={'h5'}>Pris: {currencyFormat(offer.price - offer.discount)}{offer.discount ? ` (Inkludert ${currencyFormat(offer.discount)} rabatt)` : ''}</Typography>
              <Divider style={{margin: '16px 0'}} />
              <Typography variant={'body1'} style={{fontWeight: 'bold'}}>Kontaktinformasjon</Typography>
              <Typography variant={'body2'}>
                E-post: {offer.customerEmail}
              </Typography>
              <Typography variant={'body2'}>
                Telefonnummer: {offer.customerPhoneNumber}
              </Typography>
              <Typography variant={'body2'}>
                Adresse: {offer.customerAddress}
              </Typography>
              <Divider style={{margin: '16px 0'}} />
              <Button component={Link} variant={'outlined'} to={`/anbud/${offer.id}?returner=/dashboard`}>
                Vis detaljer
              </Button>
            </Box>
          </Card>
        )
      })}
      {
        //If Pagination is rendered when totalPages goes from > 1 to <=1 the app crashes
        totalPages > 1 ? (
          <Box display={'flex'} justifyContent={'center'}>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} />
          </Box>
        ) : null}
    </>
  )
}
