import React, {useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import axios from 'axios'
import {API_URL} from '../environment'
import {Form} from './Form'
import {useFetch, useHeaders} from '../utils/apiUtils'
import {PreviousPathBar} from './PreviousPathBar'
import {useHistory} from 'react-router-dom'
import {mergeObjectArray} from '../utils/objectUtils'
import {usePersistentState} from '../utils/hooks'

const getInitialFormData = (fields) => {
  return mergeObjectArray(Object.keys(fields).map(key => {
    const field = fields[key]
    return {[key]: field.defaultValue !== undefined ? field.defaultValue : ''}
  }))
}

export const PersistentSubmitForm = ({fields, persistentKey, ...props}) => {
  const initialFormData = getInitialFormData(fields)
  const [formData, setFormData] = usePersistentState(persistentKey, initialFormData)
  return <SubmitFormContent fields={fields} formData={formData} setFormData={setFormData} initialFormData={initialFormData} {...props} />
}

export const SubmitForm = ({fields, ...props}) => {
  const initialFormData = getInitialFormData(fields)
  const [formData, setFormData] = useState(initialFormData)
  return <SubmitFormContent fields={fields} formData={formData} setFormData={setFormData} initialFormData={initialFormData} {...props} />
}

const SubmitFormContent = ({title, submitText, fields, apiSubmitPath, apiRequestType, apiFetchPath, previousPath, nextPath, dataKey, onCompleted, transformData, formData, setFormData, initialFormData}) => {
  const [loading, setLoading] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const headers = useHeaders()
  const history = useHistory()
  const existingDataResponse = useFetch(apiFetchPath, setLoading)

  useEffect(() => {
    if (!existingDataResponse) return
    const unfilteredExistingData = existingDataResponse[dataKey]
    if (!unfilteredExistingData) return
    const filteredExistingData = mergeObjectArray(
      Object.keys(unfilteredExistingData)
        .filter(key => key in fields)
        .map(key => ({[key]: unfilteredExistingData[key]}))
    )

    setFormData(filteredExistingData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingDataResponse])

  const handleSubmit = () => {
    setLoading(true)

    const transformedData = transformData ? transformData(formData) : formData

    axios[apiRequestType || 'post'](`${API_URL}${apiSubmitPath}`, transformedData, {headers})
      .then(response => {
        enqueueSnackbar('Vellykket!', {variant: 'success'})
        if (apiRequestType === 'post') setFormData(initialFormData)
        setLoading(false)
        if (onCompleted) onCompleted(response, transformedData)
        if (previousPath) history.push(previousPath)
        else if (nextPath) history.push(nextPath)
      })
      .catch(error => {
        setLoading(false)
        console.error(error)
        enqueueSnackbar(error?.response?.data?.message ?? 'Noe gikk galt', {variant: 'error'})
      })
  }

  return (
    <>
      {previousPath && <PreviousPathBar previousPath={previousPath} />}
      <Form fields={fields} formData={formData} setFormData={setFormData} onSubmit={handleSubmit} title={title} submitText={submitText} loading={loading} />
    </>
  )
}
