import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {useIsAuthorized} from '../utils/authenticationUtils'
import {Box, Container} from '@material-ui/core'
import {ResponsiveAppMenu} from './ResponsiveAppMenu'
import {useSnackbar} from 'notistack'

export const AuthorizedRoute = ({role, ...props}) => {
  const {enqueueSnackbar} = useSnackbar()
  const isAuthorized = useIsAuthorized(role)

  if (!isAuthorized) {
    enqueueSnackbar('Du har ikke tilgang her', {variant: 'error'})
    return <Redirect to='/' />
  }

  return (
    <Box display={'flex'}>
      <ResponsiveAppMenu />
      <Box component={'main'} py={24} flexGrow={1}>
        <Container>
          <Route {...props} />
        </Container>
      </Box>
    </Box>
  )
}
