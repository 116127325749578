import {BottomNavigationAction, makeStyles} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(({palette}) => ({
  bottomNavigationButton: {
    color: palette.text.primary,
    borderLeft: '1px solid #ddd',
    borderRight: '1px solid #ddd',
    maxWidth: 'none',
    '&:hover': {
      background: '#ddd'
    }
  }
}))

export const BottomNavigationButton = ({...props}) => {
  const classes = useStyles()
  return (
    <BottomNavigationAction className={classes.bottomNavigationButton} {...props} />
  )
}