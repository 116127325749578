import {AppBar as MaterialAppBar, Toolbar, useMediaQuery, useTheme} from '@material-ui/core'
import React from 'react'

export const AppBar = ({children}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const left = isMobile ? 36 : 200
  return (
    <MaterialAppBar position={'fixed'} color={'transparent'} style={{left, width: `calc(100% - ${left}px)`}}>
      <Toolbar>
        {children}
      </Toolbar>
    </MaterialAppBar>
  )
}
