import {useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import axios from 'axios'
import {API_URL} from '../environment'
import {useAccessToken, useIsAdmin} from './authenticationUtils'

export const useHeaders = () => {
  const accessToken = useAccessToken()
  return accessToken ? {'Authorization': `Bearer ${accessToken}`} : null
}

export const useFetch = (apiPath, setLoading, adminOnly) => {
  const isAdmin = useIsAdmin()
  const [data, setData] = useState([])
  const {enqueueSnackbar} = useSnackbar()
  const headers = useHeaders()

  useEffect(() => {
    if (!apiPath || (!isAdmin && adminOnly)) return
    if (setLoading) setLoading(true)
    axios.get(`${API_URL}${apiPath}`, {headers}).then(response => {
      if (!response?.data) {
        enqueueSnackbar('Noe gikk galt', {variant: 'error'})
        if (setLoading) setLoading(false)
        return
      }

      if (setLoading) setLoading(false)
      setData(response.data)
    })
      .catch(error => {
        console.error(error)
        enqueueSnackbar(error?.response?.data?.message ?? 'Noe gikk galt', {variant: 'error'})
        if (setLoading) setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return data
}
