import React, {useEffect, useState} from 'react'
import {useFetch} from '../utils/apiUtils'
import {Box, Button, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {Link} from 'react-router-dom'
import {Loader, SortableTable, TextField} from './ui'
import {isAuthorized, useCurrentUser} from '../utils/authenticationUtils'
import Mark from 'mark.js';

export const FetchAndCreate = ({title, createButtonText, apiPath, createPath, dataKey, columns, createRows}) => {
  const [loading, setLoading] = useState(false)
  const currentUser = useCurrentUser()

  const markContainer = useState({
    markInstancenew: new Mark(document.querySelector("#search-node"))
  });
  const data = useFetch(apiPath, setLoading)
  const items = data && data[dataKey] ? data[dataKey] : []
  const rows = createRows(items)

  const filteredColumns = columns.filter(column => isAuthorized(currentUser.role, column.role))

  const [filter, setFilter] = useState('');

  useEffect(() => {
    markContainer.markInstance = new Mark(document.querySelector("#search-node"))
    markContainer.markInstance.unmark({
      done: () => {
        markContainer.markInstance.mark(filter);
      }
    });
  });
  
  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={4}>
        <Box>
          <Typography variant={'h1'} style={{marginBottom: 0}}>{title}</Typography>
        </Box>
        <Box>
          <TextField
            label="Søk i tabell"
            value={filter}
            onChange={e => setFilter(e.target.value ?? '')}
          />
        </Box>
        <Box>
          {createPath && <Button component={Link} variant={'outlined'} startIcon={<AddIcon />} to={createPath}>{createButtonText}</Button>}
        </Box>
      </Box>
      <div id="search-node">
        <SortableTable filterString={filter} columns={filteredColumns} rows={rows} loading={loading} />
      </div>
      {loading && <Loader />}
    </>
  )
}


