import React from 'react'
import {SubmitForm} from '../../components/SubmitForm'
import {usePriceFieldFields} from './PriceField'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

export const CreatePriceField = () => {
  const priceFieldFields = usePriceFieldFields()
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)

  return (
    <SubmitForm
      fields={priceFieldFields}
      title={'Opprett prisfelt'}
      apiSubmitPath={'/price-fields'}
      previousPath={previousPath} />
  )
}
