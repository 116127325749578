import React from 'react'
import {dateFormat} from '../../utils/dateUtils'
import {OrganizationLink, PriceFieldLink, UserLink} from '../../components/Links'
import {FetchAndCreate} from '../../components/FetchAndCreate'
import {currencyFormat} from '../../utils/numberUtils'

const columns = [{
  title: 'Navn',
  key: 'name',
  sortable: true
}, {
  title: 'Pris',
  key: 'price',
  sortable: true
}, {
  title: 'Soner',
  key: 'zones',
  sortable: true
}, {
  title: 'Organisasjon',
  key: 'organization',
  sortable: true,
  role: 'admin'
}, {
  title: 'Opprettet',
  key: 'createdAt',
  sortable: true
}, {
  title: 'Opprettet av',
  key: 'createdBy',
  sortable: true
}]

const createRows = (priceFields) => {
  return priceFields.map((priceField) => {
    const zonesString = priceField.zones.map(zone => zone.name).join(', ')

    return {
      name: {
        renderValue: <PriceFieldLink id={priceField.id} name={priceField.name} />,
        sortValue: priceField.name
      },
      price: {
        renderValue: currencyFormat(priceField.price),
        searchValue: 'renderValue',
        sortValue: priceField.price
      },
      zones: {
        renderValue: zonesString,
        sortValue: zonesString
      },
      organization: {
        renderValue: <OrganizationLink id={priceField.organizationId} name={priceField.organizationName} />,
        sortValue: priceField.organizationName
      },
      createdAt: {
        renderValue: dateFormat.format(Date.parse(priceField.createdAt)),
        sortValue: priceField.createdAt,
        searchValue: 'renderValue',
      },
      createdBy: {
        renderValue: <UserLink id={priceField.createdById} name={priceField.createdByName} />,
        sortValue: priceField.createdByName
      }
    }
  })
}

export const PriceFields = () => {
  return (
    <FetchAndCreate
      title={'Prisfelter'}
      createButtonText={'Opprett prisfelt'}
      apiPath={'/price-fields'}
      createPath={'/opprett-prisfelt?returner=/prisfelter'}
      dataKey={'priceFields'}
      columns={columns}
      createRows={createRows}
    />
  )
}
