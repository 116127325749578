import React from 'react'
import {Link} from 'react-router-dom'

export const OrganizationLink = ({id, name}) => {
  return (
    <Link to={`/organisasjoner/${id}`}>
      {name || id}
    </Link>
  )
}

export const UserLink = ({id, name}) => {
  return (
    <Link to={`/brukere/${id}`}>
      {name || id}
    </Link>
  )
}

export const ZoneLink = ({id, name}) => {
  return (
    <Link to={`/soner/${id}`}>
      {name || id}
    </Link>
  )
}

export const PriceFieldLink = ({id, name}) => {
  return (
    <Link to={`/prisfelter/${id}`}>
      {name || id}
    </Link>
  )
}
