import React from 'react'
import {SubmitForm} from '../../components/SubmitForm'
import {useZoneFields} from './Zone'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

export const CreateZone = () => {
  const zoneFields = useZoneFields()
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)
  return (
    <SubmitForm
      fields={zoneFields}
      title={'Opprett sone'}
      apiSubmitPath={'/zones'}
      previousPath={previousPath} />
  )
}
