import React from 'react'
import {useLocation} from 'react-router-dom'
import {Container} from '@material-ui/core'
import {CenteredBox} from '../../components/ui'
import {SubmitForm} from '../../components/SubmitForm'
import queryString from 'query-string'

const useResetPasswordFields = (username, token) => {
  return {
    username: {
      title: 'E-post',
      type: 'email',
      defaultValue: username,
      required: true,
      minLength: 3,
      hidden: true
    },
    token: {
      title: 'Token',
      type: 'text',
      defaultValue: token,
      required: true,
      minLength: 3,
      hidden: true
    },
    password: {
      title: 'Passord',
      key: 'password',
      type: 'password',
      required: true,
      minLength: 3
    }
  }
}

export const ResetPassword = () => {
  const location = useLocation()
  const {username, token} = queryString.parse(location.search)
  const fields = useResetPasswordFields(username, token)

  const handleCompleted = (response) => {
    localStorage.setItem('accessToken', response.data.accessToken)
  }

  return (
    <Container style={{maxWidth: 900}}>
      <CenteredBox>
        <SubmitForm
          fields={fields}
          title={'Opprett passord'}
          apiSubmitPath={`/users/${username}/reset-password`}
          apiRequestType={'put'}
          onCompleted={handleCompleted}
          nextPath={'/dashboard'}
        />
      </CenteredBox>
    </Container>
  )
}
