import React from 'react'
import {SubmitForm} from '../../components/SubmitForm'
import {useLocation, useParams} from 'react-router-dom'
import {useCurrentUser} from '../../utils/authenticationUtils'
import queryString from 'query-string'

export const organizationFields = {
  name: {
    title: 'Navn',
    type: 'text',
    required: true,
    minLength: 3,
    role: 'owner'
  },
  address: {
    title: 'Adresse',
    type: 'text',
    required: true,
    minLength: 3,
    role: 'owner'
  },
  organizationNumber: {
    title: 'Org.nr',
    type: 'tel',
    required: true,
    stripSpaces: true,
    minLength: 9,
    maxLength: 9,
    role: 'owner'
  },
  phoneNumber: {
    title: 'Telefonnummer',
    type: 'tel',
    required: true,
    stripSpaces: true,
    minLength: 4,
    maxLength: 12
  },
  email: {
    title: 'E-post',
    type: 'email',
    required: true
  },
  logoUrl: {
    title: 'Logo',
    type: 'file',
    role: 'owner'
  },
  offerTerms: {
    title: 'Informasjon i tilbud',
    type: 'rich'
  },
  hideUnitPrices: {
    title: 'Skjul stykkpriser i tilbud',
    type: 'boolean',
    defaultValue: false,
  },
  active: {
    title: 'Aktiv',
    defaultValue: true,
    type: 'boolean'
  }
}


export const Organization = () => {
  const {id} = useParams()
  const currentUser = useCurrentUser()
  const isCurrentOrganization = currentUser.organizationId.toString() === id
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)

  return (
    <SubmitForm
      fields={organizationFields}
      title={isCurrentOrganization ? 'Min organisasjon' : 'Organisasjon'}
      submitText={'Oppdater organisasjon'}
      apiSubmitPath={`/organizations/${id}`}
      apiFetchPath={`/organizations/${id}`}
      dataKey={'organization'}
      previousPath={previousPath}
      apiRequestType={'put'}
    />
  )
}
