import {Box} from '@material-ui/core'
import React, {useMemo} from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import {PersistentSubmitForm} from '../../components/SubmitForm'
import {DetailedOfferTable, SimpleOfferTable} from '../../components/OfferTable'
import {downloadFile} from '../../utils/blobUtils'

export const OfferExpansionPanels = ({offer}) => {
  const [expanded, setExpanded] = React.useState(0)

  const handleExpandedChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false)
  }

  const expansionPanels = [{
    title: 'Enkel oppsummering',
    component: <SimpleOfferTable offer={offer} />
  }, {
    title: 'Detaljert oppsummering',
    component: <DetailedOfferTable offer={offer} />
  }]

  return (
    <Box maxWidth={500}>
      {expansionPanels.map((expansionPanel, index) => {
        return (
          <ExpansionPanel key={index} expanded={expanded === index} onChange={handleExpandedChange(index)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{background: '#fafafa', border: '1px solid #ddd'}}>
              <Typography variant={'h5'} style={{margin: 0}}>{expansionPanel.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{justifyContent: 'center'}}>
              {expansionPanel.component}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </Box>
  )
}

const confirmFields = {
  name: {
    title: 'Navn',
    type: 'text',
    required: true,
    minLength: 3
  },
  email: {
    title: 'E-post',
    type: 'email',
    required: true,
    minLength: 3
  },
  phoneNumber: {
    title: 'Telefonnummer',
    type: 'tel',
    required: true,
    minLength: 3
  },
  address: {
    title: 'Adresse',
    type: 'text',
    required: true,
    minLength: 3
  },
  discount: {
    title: 'Rabatt',
    type: 'number',
    defaultValue: ''
  },
  send: {
    title: 'Send på e-post',
    type: 'boolean',
    defaultValue: true
  },
  download: {
    title: 'Last ned PDF',
    type: 'boolean',
    defaultValue: false
  }
}

export const ConfirmOffer = ({offer, resetForm}) => {
  const filteredOffer = useMemo(() => {
    const filteredOfferZones = offer.offerZones
      .map(offerZone => {
        const filteredOfferZonePriceFields = offerZone.offerZonePriceFields.filter(offerZonePriceField => !!offerZonePriceField.quantity)
        return {...offerZone, offerZonePriceFields: filteredOfferZonePriceFields}
      })
      .filter(offerZone => !!offerZone.offerZonePriceFields.length)

    const prices = filteredOfferZones.map(offerZone => offerZone.offerZonePriceFields.map(offerZonePriceField => offerZonePriceField.quantity * offerZonePriceField.price)).flat()
    const price = prices.reduce((a, b) => a + b, 0)

    return {
      ...offer,
      offerZones: filteredOfferZones,
      price
    }
  }, [offer])

  const transformData = (confirmFormData) => {
    const customer = {
      name: confirmFormData.name,
      email: confirmFormData.email,
      phoneNumber: confirmFormData.phoneNumber,
      address: confirmFormData.address
    }

    const filteredOfferWithDiscount = {
      ...filteredOffer,
      send: confirmFormData.send,
      download: confirmFormData.download,
      discount: confirmFormData.discount
    }

    return {offer: filteredOfferWithDiscount, customer}
  }

  const handleCompleted = (response, submittedData) => {
    if (response?.data?.file) downloadFile(response.data.file, [
      'Anbud',
      'Adresse',
      submittedData.customer.address,
      'Tlf',
      submittedData.customer.phoneNumber,
      'Navn',
      submittedData.customer.name,
      'Epost',
      submittedData.customer.email,
    ].join('_'))
    resetForm()
  }

  return (
    <>
      <Typography style={{textAlign: 'center'}} variant={'h1'}>Send anbud</Typography>
      <Box display={'flex'} justifyContent={'center'}>
        <OfferExpansionPanels offer={filteredOffer} />
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Box maxWidth={400} width={'100%'}>
          <PersistentSubmitForm apiSubmitPath={'/offers'} fields={confirmFields} submitText={'Lagre anbud'} transformData={transformData} onCompleted={handleCompleted} nextPath={'/dashboard'} persistentKey={'confirmOffer'} />
        </Box>
      </Box>
    </>
  )
}
