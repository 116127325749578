import * as React from 'react'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import ChevronUpIcon from '@material-ui/icons/ExpandLess'
import { TableCell, makeStyles, Box, Typography } from '@material-ui/core'

const useHeadCellStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.text.primary,
    color: palette.background.paper,
    borderRight: `1px solid ${palette.background.paper}`,
    borderLeft: `1px solid ${palette.background.paper}`,
    cursor: ({ sortable }) => sortable ? 'pointer' : 'unset',
    '&:hover': {
      backgroundColor: ({ sortable }) => sortable ? '#444' : undefined
    }
  }
}))

const SortIcon = ({sortDirection, compact}) => {
  const Icon = sortDirection === 'asc' ? ChevronUpIcon : ChevronDownIcon
  return (
    <Icon display={'block'} fontSize={'small'} />
  )
}

export const TableHeadCell = ({ column, sortField, sortDirection, handleSort, compact, ...props }) => {
  const headCellClasses = useHeadCellStyles({ sortable: column.sortable, compact })

  return (
    <TableCell className={headCellClasses.root} onClick={column.sortable ? handleSort(column.key) : undefined} {...props}>
      <Box display='flex' alignItems='center'>
        <Typography variant='caption'>{column.title}</Typography>
        {sortField === column.key && <SortIcon sortDirection={sortDirection} compact={compact} />}
      </Box>
    </TableCell>
  )
}
