import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import {TableRow} from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import {TableBodyCell} from './ui/table'
import {currencyFormat} from '../utils/numberUtils'
import React from 'react'
import {getUniqueName} from '../pages/Offer/CreateOffer'

const OfferTableHead = () => {
  const headCellTitles = ['', 'Antall', 'Stykkpris', 'Pris']

  return (
    <TableHead>
      <TableRow>
        {headCellTitles.map(((title, i) => {
          return <TableCell key={i} style={{textAlign: 'center'}}>{title}</TableCell>
        }))}
      </TableRow>
    </TableHead>
  )
}

const OfferTableTotalRow = ({total, discount, colSpan}) => {
  return (
    <>
      {!!discount && (
        <TableRow style={{borderTop: '2px solid black'}}>
          <TableBodyCell colSpan={colSpan} style={{borderBottom: 'none'}}>
            Rabatt
          </TableBodyCell>
          <TableBodyCell style={{textAlign: 'right', borderBottom: 'none'}}>
            -{currencyFormat(discount)}
          </TableBodyCell>
        </TableRow>
      )}
      <TableRow style={discount ? {} : {borderTop: '2px solid black'}}>
        <TableBodyCell colSpan={colSpan} style={{borderBottom: 'none'}}>
          <b>Totalt ekskl. mva.</b>
        </TableBodyCell>
        <TableBodyCell style={{textAlign: 'right', borderBottom: 'none', minWidth: 55}}>
          <b>{currencyFormat(discount ? total - discount : total)}</b>
        </TableBodyCell>
      </TableRow>
    </>
  )
}

export const OfferTableBodyRow = ({offerZonePriceField}) => {
  const subTotal = offerZonePriceField.quantity * offerZonePriceField.price
  const bodyCells = [{
    value: offerZonePriceField.name,
  }, {
    value: offerZonePriceField.quantity,
    style: {textAlign: 'right'}
  }, {
    value: currencyFormat(offerZonePriceField.price),
    style: {textAlign: 'right'}
  }, {
    value: currencyFormat(subTotal),
    style: {textAlign: 'right', minWidth: 55}
  }]

  return (
    <TableRow key={offerZonePriceField.id}>
      {bodyCells.map((bodyCell, i) => {
        return (
          <TableBodyCell key={i} style={bodyCell.style}>
            {bodyCell.value}
          </TableBodyCell>
        )
      })}
    </TableRow>
  )
}

export const SimpleOfferTable = ({offer}) => {
  const offerZonePriceFieldMap = {}
  offer.offerZones.forEach(offerZone => {
    offerZone.offerZonePriceFields.forEach(offerZonePriceField => {
      const key = offerZonePriceField.priceFieldId
      if (offerZonePriceFieldMap[key]) offerZonePriceFieldMap[key].quantity = offerZonePriceFieldMap[key].quantity + offerZonePriceField.quantity
      else offerZonePriceFieldMap[key] = {name: offerZonePriceField.name, quantity: offerZonePriceField.quantity, price: offerZonePriceField.price}
    })
  })

  return (
    <Table size={'small'}>
      <OfferTableHead />
      <TableBody>
        {Object.values(offerZonePriceFieldMap).map((offerZonePriceField, i) => {
          return <OfferTableBodyRow key={i} offerZonePriceField={offerZonePriceField} />
        })}
        <OfferTableTotalRow total={offer.price} discount={offer.discount} colSpan={3} />
      </TableBody>
    </Table>
  )
}

export const DetailedOfferTable = ({offer}) => {
  return (
    <Table size={'small'}>
      <OfferTableHead />
      <TableBody>
        {offer.offerZones.map(offerZone => {
          const name = getUniqueName(offer, offerZone)

          return (
            <React.Fragment key={offerZone.id}>
              <TableRow>
                <TableBodyCell colSpan={4} style={{background: '#ddd'}}>
                  <b>{name}</b>
                </TableBodyCell>
              </TableRow>
              {offerZone.offerZonePriceFields.map((offerZonePriceField) => {
                const subTotal = offerZonePriceField.quantity * offerZonePriceField.price

                return (
                  <TableRow key={offerZonePriceField.id}>
                    <TableBodyCell>
                      {offerZonePriceField.name}
                    </TableBodyCell>
                    <TableBodyCell style={{textAlign: 'right'}}>
                      {offerZonePriceField.quantity}
                    </TableBodyCell>
                    <TableBodyCell style={{textAlign: 'right'}}>
                      {currencyFormat(offerZonePriceField.price)}
                    </TableBodyCell>
                    <TableBodyCell style={{textAlign: 'right'}}>
                      {currencyFormat(subTotal)}
                    </TableBodyCell>
                  </TableRow>
                )
              })}
            </React.Fragment>
          )
        })}
        <OfferTableTotalRow total={offer.price} discount={offer.discount} colSpan={3} />
      </TableBody>
    </Table>
  )
}
