import {useFetch} from './apiUtils'
import {roles, useCurrentUser} from './authenticationUtils'

const {useState} = require('react')

const {useEffect} = require('react')

export const useOrganizationOptions = () => {
  const data = useFetch('/organizations', null, true)
  const organizations = data?.organizations ?? []
  const organizationOptions = organizations.map((organization => ({title: organization.name, value: organization.id})))

  return organizationOptions
}

export const useZoneOptions = () => {
  const data = useFetch('/zones')
  const zones = data?.zones ?? []
  const zoneOptions = zones.map((zone => ({title: zone.name, value: zone.id})))

  return zoneOptions
}

export const useRoleOptions = () => {
  const currentUser = useCurrentUser()
  if (!currentUser) return false

  const roleOptions = [{title: 'Bruker', value: 'user'}, {title: 'Eier', value: 'owner'}, {title: 'Admin', value: 'admin'}]
  const filteredRoleOptions = roleOptions.filter(roleOption => roles[currentUser.role] >= roles[roleOption.value])
  return filteredRoleOptions
}


export const usePersistentState = (key, defaultValue) => {
  const [state, setState] = useState(defaultValue)

  useEffect(() => {
    setState(localStorage.getItem(key) ? JSON.parse(decodeURIComponent(escape(atob(localStorage.getItem(key))))) : defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    localStorage.setItem(key, btoa(unescape(encodeURIComponent(JSON.stringify(state)))))
  }, [key, state]);

  return [state, setState]
}

export const useLocalStorageVersion = (version) => {
  const key = 'localStorageVersion'

  useEffect(() => {
    const currentVersion = localStorage.getItem(key)
    if (!currentVersion || currentVersion !== version) {
      localStorage.clear()
      localStorage.setItem(key, version)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
