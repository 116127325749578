import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {AuthorizedRoute} from '../components/AuthorizedRoute'
import {Home} from './Home'
import {Login} from './Authentication/Login'
import {LogOut} from './Authentication/LogOut'
import {Dashboard} from './Dashboard'
import {Users} from './User/Users'
import {User} from './User/User'
import {CreateUser} from './User/CreateUser'
import {Offers} from './Offer/Offers'
import {Offer} from './Offer/Offer'
import {CreateOffer} from './Offer/CreateOffer'
import {Zones} from './Zone/Zones'
import {Zone} from './Zone/Zone'
import {CreateZone} from './Zone/CreateZone'
import {PriceFields} from './PriceField/PriceFields'
import {Organizations} from './Organization/Organizations'
import {Organization} from './Organization/Organization'
import {CreateOrganization} from './Organization/CreateOrganization'
import {PriceField} from './PriceField/PriceField'
import {CreatePriceField} from './PriceField/CreatePriceField'
import {ResetPassword} from './Authentication/ResetPassword'

export const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/logg-inn' component={Login} />
      <Route path='/logg-ut' component={LogOut} />
      <Route path='/tilbakestill-passord' component={ResetPassword} />

      <AuthorizedRoute path='/dashboard' component={Dashboard} />

      <AuthorizedRoute exact path='/brukere' component={Users} role={'owner'} />
      <AuthorizedRoute path='/brukere/:id' component={User} />
      <AuthorizedRoute path='/opprett-bruker' component={CreateUser} role={'admin'} />

      <AuthorizedRoute exact path='/anbud' component={Offers} role={'owner'} />
      <AuthorizedRoute path='/anbud/:id' component={Offer} />
      <AuthorizedRoute path='/opprett-anbud' component={CreateOffer} />

      <AuthorizedRoute exact path='/soner' component={Zones} role={'owner'} />
      <AuthorizedRoute path='/soner/:id' component={Zone} role={'owner'} />
      <AuthorizedRoute path='/opprett-sone' component={CreateZone} role={'owner'} />

      <AuthorizedRoute exact path='/prisfelter' component={PriceFields} role={'owner'} />
      <AuthorizedRoute path='/prisfelter/:id' component={PriceField} role={'owner'} />
      <AuthorizedRoute path='/opprett-prisfelt' component={CreatePriceField} />

      <AuthorizedRoute exact path='/organisasjoner' component={Organizations} role={'admin'} />
      <AuthorizedRoute path='/organisasjoner/:id' component={Organization} role={'owner'} />
      <AuthorizedRoute path='/opprett-organisasjon' component={CreateOrganization} role={'admin'} />

      <Redirect from={'*'} to={'/dashboard'} />
    </Switch>
  )
}
