import React from 'react'
import {dateFormat} from '../../utils/dateUtils'
import {OrganizationLink, UserLink} from '../../components/Links'
import {FetchAndCreate} from '../../components/FetchAndCreate'

const columns = [{
  title: 'Navn',
  key: 'name',
  sortable: true
}, {
  title: 'Adresse',
  key: 'address',
  sortable: true
}, {
  title: 'Org.nr.',
  key: 'organizationNumber',
  sortable: true
}, {
  title: 'Logo',
  key: 'logo',
  sortable: false
}, {
  title: 'Opprettet',
  key: 'createdAt',
  sortable: true
}, {
  title: 'Opprettet av',
  key: 'createdBy',
  sortable: true
}]

const createRows = (organizations) => {
  return organizations.map((organization) => {
    return {
      name: {
        renderValue: <OrganizationLink id={organization.id} name={organization.name} />,
        sortValue: organization.name
      },
      address: {
        renderValue: organization.address,
        sortValue: organization.address
      },
      organizationNumber: {
        renderValue: organization.organizationNumber,
        sortValue: organization.organizationNumber
      },
      logo: {
        renderValue: organization.logoUrl ? <img src={organization.logoUrl} alt={`${organization.name} logo`} style={{maxHeight: 30, maxWidth: 30}} /> : null
      },
      createdAt: {
        renderValue: dateFormat.format(Date.parse(organization.createdAt)),
        sortValue: organization.createdAt,
        searchValue: 'renderValue',
      },
      createdBy: {
        renderValue: <UserLink id={organization.createdById} name={organization.createdByName} />,
        sortValue: organization.createdByName
      }
    }
  })
}

export const Organizations = () => {
  return (
    <FetchAndCreate
      title={'Organisasjoner'}
      createButtonText={'Opprett organisasjon'}
      apiPath={'/organizations'}
      createPath={'/opprett-organisasjon?returner=/organisasjoner'}
      dataKey={'organizations'}
      columns={columns}
      createRows={createRows}
    />
  )
}
