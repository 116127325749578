import {Box, Drawer, makeStyles, useMediaQuery, useTheme} from '@material-ui/core'
import React from 'react'

export const drawerWidth = 200

const useStyles = makeStyles(({breakpoints}) => ({
  drawer: {
    [breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
}))

export const MenuDrawer = ({content, mobileOpen, toggleDrawer, anchor = 'left', top = 0}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box component={'nav'} className={classes.drawer}>
      {isMobile ? (
        <Drawer
          variant={'temporary'}
          anchor={anchor}
          open={mobileOpen}
          onClose={toggleDrawer}
          classes={{paper: classes.drawerPaper}}
          ModalProps={{keepMounted: true}}
        >
          {content}
        </Drawer>
      ) : (
        <Drawer anchor={anchor} classes={{paper: classes.drawerPaper}} variant={'permanent'} open PaperProps={{style: {top: top}}}>
          {content}
        </Drawer>
      )}
    </Box>
  )
}