import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {Routes} from './pages/Routes'
import 'typeface-roboto'
import {SnackbarProvider} from 'notistack'
import {ThemeProvider} from '@material-ui/styles'
import {lightTheme} from './components/ui'

export const App = () => {
  return (
    <Router>
      <ThemeProvider theme={lightTheme}>
        <SnackbarProvider anchorOrigin={{vertical: 'top', horizontal: 'right'}} maxSnack={5} dense>
          <Routes />
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  )
}
