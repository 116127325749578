import React, {useState} from 'react'
import {useLocation, useParams, useHistory} from 'react-router-dom'
import {useFetch, useHeaders} from '../../utils/apiUtils'
import {DetailedOfferTable, SimpleOfferTable} from '../../components/OfferTable'
import {PreviousPathBar} from '../../components/PreviousPathBar'
import queryString from 'query-string'
import Card from '@material-ui/core/Card'
import {Box, useMediaQuery, useTheme} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {Customer} from './Offers'
import {dateFormat} from '../../utils/dateUtils'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import CopyIcon from '@material-ui/icons/FileCopy'
import EmailIcon from '@material-ui/icons/Email'
import DeleteIcon from '@material-ui/icons/Delete'
import {BottomNavigationButton} from '../../components/ui/bottomNavigation/BottomNavigationButton'
import {BottomNavigation} from '../../components/ui/bottomNavigation/BottomNavigation'
import axios from 'axios'
import {API_URL} from '../../environment'
import {useSnackbar} from 'notistack'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import {Loader} from '../../components/ui'
import {downloadFile} from '../../utils/blobUtils'

const OfferBottomNavigation = ({offer, previousPath, loading, setLoading}) => {
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const headers = useHeaders()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const left = isMobile ? 0 : 200
  const history = useHistory()

  const copyOffer = () => {
    const encodedOffer = btoa(unescape(encodeURIComponent(JSON.stringify(offer))))
    localStorage.setItem('offer', encodedOffer)
    const confirmOffer = {
      name: offer.customerName,
      email: offer.customerEmail,
      phoneNumber: offer.customerPhoneNumber,
      address: offer.customerAddress,
      discount: offer.discount
    }
    const encodedConfirmOffer = btoa(unescape(encodeURIComponent(JSON.stringify(confirmOffer))))
    localStorage.setItem('offer', encodedOffer)
    localStorage.setItem('confirmOffer', encodedConfirmOffer)
    history.push('/opprett-anbud')
  }

  const sendOffer = () => {
    setLoading(true)
    axios.post(`${API_URL}/offers/send`, {offer}, {headers}).then(response => {
      if (!response) {
        setLoading(false)
        enqueueSnackbar('Noe gikk galt', {variant: 'error'})
        return
      }

      enqueueSnackbar('Vellykket!', {variant: 'success'})
    })
      .catch(error => {
        console.error(error)
        setLoading(false)
        enqueueSnackbar(error?.response?.data?.message ?? 'Noe gikk galt', {variant: 'error'})
      })
  }

  const downloadOfferPDF = async () => {
    setLoading(true)
    axios.post(`${API_URL}/offers/pdf`, {offer}, {headers: {...headers, responseType: 'arraybuffer'}}).then(response => {
      const file = response?.data?.file

      if (!file) {
        enqueueSnackbar('Noe gikk galt', {variant: 'error'})
        setLoading(false)
        return
      }

      downloadFile(file, [
        'Anbud',
        'Id',
        offer.id,
        'Adresse',
        offer.customerAddress,
        'Tlf',
        offer.customerPhoneNumber,
        'Navn',
        offer.customerName,
        'Epost',
        offer.customerEmail,
      ].join('_'))
      setLoading(false)
    })
      .catch(error => {
        console.error(error)
        enqueueSnackbar(error?.response?.data?.message ?? 'Noe gikk galt', {variant: 'error'})
        setLoading(false)
      })
  }

  const deleteOffer = () => {
    setLoading(true)
    axios.put(`${API_URL}/offers/${offer.id}`, {active: false}, {headers}).then(response => {
      if (!response) {
        enqueueSnackbar('Noe gikk galt', {variant: 'error'})
        setLoading(false)
        return
      }

      enqueueSnackbar('Vellykket', {variant: 'success'})
      setLoading(false)
      history.push(previousPath || '/dashboard')
    })
      .catch(error => {
        console.error(error)
        enqueueSnackbar(error?.response?.data?.message ?? 'Noe gikk galt', {variant: 'error'})
        setLoading(false)
      })
  }

  const toggleConfirmDeleteDialogOpen = () => setConfirmDeleteDialogOpen(prevOpen => !prevOpen)

  const bottomButtons = [{
    name: 'Kopier',
    icon: <CopyIcon />,
    onClick: copyOffer
  }, {
    name: 'Last ned',
    icon: <DownloadIcon />,
    onClick: downloadOfferPDF
  }, {
    name: 'Send',
    icon: <EmailIcon />,
    onClick: sendOffer
  }, {
    name: 'Slett',
    icon: <DeleteIcon />,
    onClick: toggleConfirmDeleteDialogOpen
  }]

  return (
    <>
      <BottomNavigation left={left}>
        {bottomButtons.map(bottomButton => {
          return (
            <BottomNavigationButton disabled={loading} key={bottomButton.name} label={bottomButton.name} icon={bottomButton.icon} style={bottomButton.style} onClick={bottomButton.onClick} />
          )
        })}
      </BottomNavigation>
      {confirmDeleteDialogOpen && (
        <Dialog open onClose={toggleConfirmDeleteDialogOpen}>
          <DialogTitle>Er du sikker på at du vil slette dette anbudet?</DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={toggleConfirmDeleteDialogOpen} color="primary">
              Nei, avbryt
            </Button>
            <Button onClick={deleteOffer} color="primary" autoFocus>
              Ja, slett
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export const Offer = () => {
  const [loading, setLoading] = useState(false)
  const {id} = useParams()
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)

  const data = useFetch(`/offers/${id}`, setLoading)
  const offer = data?.offer

  if (!offer) return null

  return (
    <>
      {previousPath && <PreviousPathBar previousPath={previousPath} />}
      <Box display={'flex'} justifyContent={'center'}>
        <Box maxWidth={400}>
          <Typography variant={'h1'}>Anbud</Typography>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'body2'}>ID: {offer.id}</Typography>
            <Typography variant={'body2'}>{dateFormat.format(Date.parse(offer.createdAt))}</Typography>
          </Box>
          <Card component={Box} width={'100%'} my={2}>
            <Box py={5} px={3}>
              <Typography variant={'h3'}>Kunde</Typography>
              <Typography>
                <Customer {...offer} />
              </Typography>
            </Box>
          </Card>
          <Card component={Box} width={'100%'} my={2}>
            <Box py={5} px={3}>
              <Typography variant={'h3'}>Enkel oversikt</Typography>
              <SimpleOfferTable offer={offer} />
            </Box>
          </Card>
          <Card component={Box} width={'100%'} my={2}>
            <Box py={5} px={3}>
              <Typography variant={'h3'}>Detaljert oversikt</Typography>
              <DetailedOfferTable offer={offer} />
            </Box>
          </Card>
        </Box>
      </Box>
      <OfferBottomNavigation offer={offer} previousPath={previousPath} loading={loading} setLoading={setLoading} />
      {loading && <Loader />}
    </>
  )
}
