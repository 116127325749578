import React from 'react'
import {Box, CircularProgress} from '@material-ui/core'

export const Loader = () => {
  return (
    <Box position={'fixed'} bottom={20} right={20} zIndex={10}>
      <CircularProgress />
    </Box>
  )
}
