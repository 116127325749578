import {IconButton} from '@material-ui/core'
import {Link} from 'react-router-dom'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import React from 'react'
import {AppBar} from './ui/AppBar'

export const PreviousPathBar = ({previousPath}) => {

  return (
    <AppBar>
      <IconButton component={Link} to={previousPath}>
        <ChevronLeftIcon />
      </IconButton>
    </AppBar>
  )
}
