import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import {App} from './App'
import * as Sentry from '@sentry/react'
import * as serviceWorker from './serviceWorker'
import {isProduction} from './environment'

if (isProduction) Sentry.init({dsn: 'https://da77828d993c448ebac506d9324f51d9@o349538.ingest.sentry.io/5330852'})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
