import React from 'react'
import {Button, Box} from '@material-ui/core'
import {useSnackbar} from 'notistack'

const scOptions = {
  cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  uploadPreset: 'ml_default',
  source: ['local'],
  multiple: false,
  tags: ['logos'],
  theme: 'minimal',
  language: 'nb',
  resourceType: 'image',
  maxImageFileSize: 1500000
}

export const ImageField = ({label, value, onChange}) => {
  const {enqueueSnackbar} = useSnackbar()
  const handleDelete = () => onChange({
    persist: () => {
    }, target: {value: ''}
  })
  const upload = () => {
    window.cloudinary.openUploadWidget(scOptions, (error, response) => {
      if (error) {
        console.error(error)
        enqueueSnackbar('Noe gikk galt', {variant: 'error'})
        return
      }

      if (response.event === 'success') {
        onChange({
          persist: () => {
          }, target: {value: response.info.secure_url}
        })
      }
    })
  }

  return (
    <>
      {value && (
        <Box>
          <Box mb={2}>
            <img alt={label} src={value} style={{maxHeight: 80, maxWidth: 80}} />
          </Box>
          <Box mb={2}>
            <Button variant={'outlined'} onClick={handleDelete}>Slett {label.toLowerCase()}</Button>
          </Box>
        </Box>
      )}

      <Button variant={'outlined'} onClick={upload}>Last opp {label.toLowerCase()}</Button>
    </>
  )
}
