import React from 'react'
import {List, makeStyles} from '@material-ui/core'
import {MenuDrawer} from './ui/menu/MenuDrawer'
import {MenuListItem} from './ui/menu/MenuListItem'

const useStyles = makeStyles(() => ({
  list: {
    padding: 0
  }
}))

const OfferZoneListItem = ({zone, onClick}) => {
  const item = {...zone, title: zone.name}
  return (
    <MenuListItem item={item} onClick={onClick} />
  )
}

const top = 65
export const OfferZoneMenu = ({zones, mobileOpen, toggleDrawer, closeDrawer, addZoneItem}) => {
  const classes = useStyles()

  const drawerContent = (
    <>
      <List className={classes.list}>
        {zones.map((zone) => {
          const handleClick = () => {
            addZoneItem(zone)
            closeDrawer()
          }
          return <OfferZoneListItem key={zone.id} zone={zone} onClick={handleClick} />
        })}
      </List>
    </>
  )

  return (
    <MenuDrawer content={drawerContent} mobileOpen={mobileOpen} toggleDrawer={toggleDrawer} anchor={'right'} top={top} height={`calc(100% - ${top}px)`} />
  )
}
