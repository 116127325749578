import React from 'react'
import {dateFormat} from '../../utils/dateUtils'
import {OrganizationLink, UserLink} from '../../components/Links'
import {FetchAndCreate} from '../../components/FetchAndCreate'
import {roleToTitle, useIsAdmin} from '../../utils/authenticationUtils'

const columns = [{
  title: 'Navn',
  key: 'name',
  sortable: true
}, {
  title: 'E-post',
  key: 'username',
  sortable: true
}, {
  title: 'Telefonnummer',
  key: 'phoneNumber',
  sortable: true
}, {
  title: 'Rolle',
  key: 'role',
  sortable: true
}, {
  title: 'Organisasjon',
  key: 'organization',
  sortable: true,
  role: 'admin'
}, {
  title: 'Opprettet',
  key: 'createdAt',
  sortable: true
}, {
  title: 'Opprettet av',
  key: 'createdBy',
  sortable: true
}]

const createRows = (users) => {
  return users.map((user) => {
    const roleString = roleToTitle(user.role)
    return {
      name: {
        renderValue: <UserLink id={user.id} name={user.name} />,
        sortValue: user.name
      },
      username: {
        renderValue: user.username,
        sortValue: user.username
      },
      phoneNumber: {
        renderValue: user.phoneNumber,
        sortValue: user.phoneNumber
      },
      role: {
        renderValue: roleString,
        sortValue: roleString
      },
      organization: {
        renderValue: <OrganizationLink id={user.organizationId} name={user.organizationName} />,
        sortValue: user.organizationName
      },
      createdAt: {
        renderValue: dateFormat.format(Date.parse(user.createdAt)),
        sortValue: user.createdAt,
        searchValue: 'renderValue',
      },
      createdBy: {
        renderValue: <UserLink id={user.createdById} name={user.createdByName} />,
        sortValue: user.createdByName
      }
    }
  })
}

export const Users = () => {
  const isAdmin = useIsAdmin()
  return (
    <FetchAndCreate
      title={'Brukere'}
      createButtonText={'Opprett bruker'}
      apiPath={'/users'}
      createPath={isAdmin ? '/opprett-bruker?returner=/brukere' : null}
      dataKey={'users'}
      columns={columns}
      createRows={createRows}
    />
  )
}

