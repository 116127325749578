import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {SubmitForm} from '../../components/SubmitForm'
import {useCurrentUser} from '../../utils/authenticationUtils'
import {useOrganizationOptions, useRoleOptions} from '../../utils/hooks'
import queryString from 'query-string'

export const useUserFields = () => {
  const organizationOptions = useOrganizationOptions()
  const currentUser = useCurrentUser()
  const roleOptions = useRoleOptions()

  return {
    name: {
      title: 'Navn',
      type: 'text',
      required: true,
      minLength: 3
    },
    username: {
      title: 'E-post',
      type: 'email',
      required: true,
      minLength: 3
    },
    phoneNumber: {
      title: 'Telefonnummer',
      type: 'tel',
      required: true,
      minLength: 4,
      maxLength: 12,
      stripSpaces: true
    },
    organizationId: {
      title: 'Organisasjon',
      options: organizationOptions,
      defaultValue: currentUser.organizationId,
      required: true,
      type: 'select',
      role: 'owner'
    },
    role: {
      title: 'Rolle',
      type: 'select',
      options: roleOptions,
      defaultValue: 'user',
      required: true,
      minLength: 3,
      role: 'owner'
    },
    active: {
      title: 'Aktiv',
      defaultValue: true,
      type: 'boolean'
    }
  }
}

export const User = () => {
  const {id} = useParams()
  const currentUser = useCurrentUser()
  const userFields = useUserFields()
  const isCurrentUser = currentUser.id.toString() === id
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)

  return (
    <SubmitForm
      fields={userFields}
      title={isCurrentUser ? 'Min bruker' : 'Bruker'}
      submitText={'Oppdater bruker'}
      apiSubmitPath={`/users/${id}`}
      apiFetchPath={`/users/${id}`}
      dataKey={'user'}
      previousPath={previousPath}
      apiRequestType={'put'}
    />
  )
}
