import React from 'react'
import {dateFormat} from '../../utils/dateUtils'
import {ZoneLink, UserLink, OrganizationLink} from '../../components/Links'
import {FetchAndCreate} from '../../components/FetchAndCreate'

const columns = [{
  title: 'Navn',
  key: 'name',
  sortable: true
}, {
  title: 'Organisasjon',
  key: 'organization',
  sortable: true,
  role: 'admin'
}, {
  title: 'Opprettet',
  key: 'createdAt',
  sortable: true
}, {
  title: 'Opprettet av',
  key: 'createdBy',
  sortable: true
}]

const createRows = (zones) => {
  return zones.map((zone) => {
    return {
      name: {
        renderValue: <ZoneLink id={zone.id} name={zone.name} />,
        sortValue: zone.name
      },
      organization: {
        renderValue: <OrganizationLink id={zone.organizationId} name={zone.organizationName} />,
        sortValue: zone.organizationName
      },
      createdAt: {
        renderValue: dateFormat.format(Date.parse(zone.createdAt)),
        sortValue: zone.createdAt,
        searchValue: 'renderValue',
      },
      createdBy: {
        renderValue: <UserLink id={zone.createdById} name={zone.createdByName} />,
        sortValue: zone.createdByName
      }
    }
  })
}

export const Zones = () => {
  return (
    <FetchAndCreate
      title={'Soner'}
      createButtonText={'Opprett sone'}
      apiPath={'/zones'}
      createPath={'/opprett-sone?returner=/soner'}
      dataKey={'zones'}
      columns={columns}
      createRows={createRows}
    />
  )
}
