import {useLocation, useParams} from 'react-router-dom'
import React from 'react'
import {SubmitForm} from '../../components/SubmitForm'
import {useOrganizationOptions, useZoneOptions} from '../../utils/hooks'
import {useCurrentUser} from '../../utils/authenticationUtils'
import queryString from 'query-string'

export const usePriceFieldFields = () => {
  const zoneOptions = useZoneOptions()
  const organizationOptions = useOrganizationOptions()
  const currentUser = useCurrentUser()

  return {
    name: {
      title: 'Navn',
      type: 'text',
      required: true,
      minLength: 3
    },
    price: {
      title: 'Pris',
      type: 'number',
      required: true,
    },
    zoneIds: {
      title: 'Soner',
      options: zoneOptions,
      multiple: true,
      type: 'select'
    },
    organizationId: {
      title: 'Organisasjon',
      options: organizationOptions,
      defaultValue: currentUser.organizationId,
      type: 'select',
      role: 'owner'
    },
    active: {
      title: 'Aktiv',
      defaultValue: true,
      type: 'boolean'
    }
  }
}

export const PriceField = () => {
  const {id} = useParams()
  const priceFieldFields = usePriceFieldFields()
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)

  return (
    <SubmitForm
      fields={priceFieldFields}
      title={'Prisfelt'}
      submitText={'Oppdater prisfelt'}
      apiSubmitPath={`/price-fields/${id}`}
      apiFetchPath={`/price-fields/${id}`}
      previousPath={previousPath}
      dataKey={'priceField'}
      apiRequestType={'put'}
    />
  )
}
