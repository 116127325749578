import React, {useMemo, useCallback, useState} from 'react'
import {makeStyles, Table, TableBody, TableHead, TablePagination, Box} from '@material-ui/core'
import {TableRow, TableHeadCell, TableBodyCell} from './index'
import {Skeleton} from '@material-ui/lab'
import {isNumeric} from '../../../utils/numberUtils'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  table: {
  },
}))

const SortableTableLoader = ({columns}) => {
  const classes = useStyles()
  const handleSort = () => () => {
  }
  return (
    <Box overflow={'auto'}>
      <Table size='small' className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => <TableHeadCell key={idx} column={column} sortField={null} sortDirection={null} handleSort={handleSort} />)}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(Array(5).keys()).map((row) => (
            <TableRow key={row}>
              {columns.map((_, idx) => (
                <TableBodyCell key={idx}><Skeleton variant='text' width='30%' height={15} /></TableBodyCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

const sort = (data, sortField, sortDirection) => {
  if (sortField == null || sortDirection === null) return data

  return data.sort((a, b) => {
    const sortValueA = a[sortField].sortValue
    const sortValueB = b[sortField].sortValue
    const ascendingSort = sortDirection === 'asc'

    if (!sortValueA && !sortValueB) return 0
    if (!sortValueA) return ascendingSort ? -1 : 1
    if (!sortValueB) return ascendingSort ? 1 : -1

    if (isNumeric(sortValueA) && isNumeric(sortValueB)) return ascendingSort ? (sortValueA) - (sortValueB) : (sortValueB) - (sortValueA)
    return ascendingSort ? (sortValueA).localeCompare(sortValueB) : -(sortValueA).localeCompare(sortValueB)
  })
}

export const SortableTable = ({columns, rows, loading, defaultRowsPerPage = 50, filterString, initialSortField, checkActive}) => {
  const classes = useStyles()
  const [sortField, setSortField] = useState(initialSortField)
  const [sortDirection, setSortDirection] = useState('asc')

  const handleSort = (field) => () => {
    if (sortField !== field) {
      setSortField(field)
      setSortDirection('asc')
    } else {
      if (sortDirection === 'asc') {
        setSortDirection('desc')
      } else {
        setSortField(null)
        setSortDirection(null)
      }
    }
  }

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
  const [page, setPage] = useState(0)
  const handleChangePage = (_, newPage) => setPage(newPage)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const visibleColumns = columns.map(col => col.key);

  const filterRow = useCallback((row) => {
    if (!filterString) return true
    const loweredFilterString = filterString.toLowerCase();

    return Object.keys(row).filter(column=>visibleColumns.includes(column)).map((column) => {
      
      const {searchValue, sortValue, renderValue} = row[column];
      const searchList = Array.isArray(searchValue) ? searchValue.join('') : searchValue;
      const searchTerm = searchValue === 'renderValue' ? renderValue : searchList;
      return (String(searchTerm) + String(sortValue)).toLowerCase().includes(loweredFilterString)

    }).reduce((acc, cur) => acc || cur, false)
  }, [filterString, visibleColumns])

  const filteredRows = useMemo(() => rows.filter(filterRow), [rows, filterRow])

  if (loading) return <SortableTableLoader columns={columns} />
  if (!rows.length) return <Typography variant={'h4'}>Listen er tom</Typography>

  const sortedRows = sort(filteredRows, sortField, sortDirection)
  return (
    <Box overflow={'auto'}>
      <Table size='small' className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => <TableHeadCell key={idx} column={column} sortField={sortField} sortDirection={sortDirection} handleSort={handleSort} />)}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIdx) => {
            const isActive = checkActive ? checkActive(row) : false
            return (
              <TableRow key={rowIdx} isActive={isActive}>
                {columns.map(({key}, colIdx) => (
                  <TableBodyCell key={`${rowIdx}-${colIdx}`}>
                    {row[key].renderValue}
                  </TableBodyCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Box px={2}>
        <TablePagination
          component='div'
          labelRowsPerPage={'Rader per side'}
          rowsPerPageOptions={[10, 50, 100, 200, 500, 1000, {value: -1, label: 'Alle'}]}
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{fontSize: 'inherit'}}
        />
      </Box>
    </Box>
  )
}
