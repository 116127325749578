import {createMuiTheme} from "@material-ui/core";

const createMyTheme = (options) => (
  createMuiTheme({
    palette: {
      error: {
        main: '#EF4056',
      },
      primary: {
        main: '#278CF2',
      },
      secondary: {
        main: '#278CF2',
      },
      text: {
        primary: 'rgba(69,74,81,1)',
        secondary: 'rgba(69,74,81,0.46)',
      },
      background: {
        default: '#fff'
      }
    },
    spacing: 4,
    typography: {
      caption: {
        fontSize: 16,
        '@media (max-width:960px)': {
          fontSize: 14
        },
        '@media (max-width:600px)': {
          fontSize: 13
        }
      },
      body1: {
        fontSize: 16,
        '@media (max-width:960px)': {
          fontSize: 13
        },
        '@media (max-width:600px)': {
          fontSize: 12
        }
      },
      body2: {fontSize: 12},
      button: {textTransform: 'none'},
      h1: {
        fontSize: 35,
        marginBottom: 16,
        '@media (max-width:960px)': {
          fontSize: 30
        },
        '@media (max-width:600px)': {
          fontSize: 20
        }
      },
      h2: {
        fontSize: 30,
        marginBottom: 20,
        '@media (max-width:960px)': {
          fontSize: 25
        },
        '@media (max-width:600px)': {
          fontSize: 20
        }
      },
      h3: {
        fontSize: 20,
        marginBottom: 16,
        '@media (max-width:960px)': {
          fontSize: 18
        },
        '@media (max-width:600px)': {
          fontSize: 16
        }
      },
      h4: {
        fontSize: 18,
        '@media (max-width:960px)': {
          fontSize: 16
        },
        '@media (max-width:600px)': {
          fontSize: 13
        }
      },
      h5: {
        fontSize: 16,
        '@media (max-width:960px)': {
          fontSize: 14
        },
        '@media (max-width:600px)': {
          fontSize: 13
        }
      },
      h6: {
        fontSize: 14,
        '@media (max-width:960px)': {
          fontSize: 12
        },
        '@media (max-width:600px)': {
          //fontSize: 11
        },
        textTransform: 'uppercase'
      },
    },
    shadows: [
      'none',
      '0px 8px 26px rgba(185, 182, 175, 0.2)',
      '0px 4px 13px rgba(185, 182, 175, 0.4)',
      '0px 4px 13px rgba(185, 182, 175, 0.8)', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''
    ],
    ...options,
  })
)

export const lightTheme = createMyTheme({
  borderRadius: 4,
  customPalette: {}
})
