import * as React from 'react'
import { TableCell } from '@material-ui/core'

export const TableBodyCell = ({ children, style, ...props }) => {
  return (
    <TableCell {...props} style={{padding: 6, ...style}}>
      {children}
    </TableCell>
  )
}
