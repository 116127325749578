import React from 'react'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import {TextField} from './TextField'
import IconButton from '@material-ui/core/IconButton'

export const CounterField = ({label, value, onChange}) => {
  const addQuantity = () => onChange({
    persist: () => {
    },
    target: {value: value + 1}
  })
  const subtractQuantity = () => onChange({
    persist: () => {
    },
    target: {value: value - 1}
  })
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <IconButton onClick={subtractQuantity} style={{padding: '0 16px'}}><RemoveIcon /></IconButton>
      <TextField label={label} value={value} onChange={onChange} type={'number'} style={{width: 250}} tall />
      <IconButton onClick={addQuantity} style={{padding: '0 16px'}}><AddIcon /></IconButton>
    </Box>
  )
}
