export const replaceAt = (array, index, value) => {
  const newArray = array.slice(0)
  newArray[index] = value
  return newArray
}

export const removeAt = (array, index) => {
  const newArray = array.slice(0)
  newArray.splice(index, 1)
  return newArray
}
