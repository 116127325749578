import React, {useEffect, useMemo, useState} from 'react'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
import {Box, IconButton, makeStyles, Toolbar, useMediaQuery, useTheme} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import {AppBar} from '../../components/ui'
import {useFetch} from '../../utils/apiUtils'
import {OfferZoneMenu} from '../../components/OfferZoneMenu'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import {FormField} from '../../components/FormField'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import RemoveIcon from '@material-ui/icons/Remove'
import {removeAt, replaceAt} from '../../utils/arrayUtils'
import Button from '@material-ui/core/Button'
import {usePersistentState} from '../../utils/hooks'
import {Link} from 'react-router-dom'
import {ConfirmOffer} from './ConfirmCreateOffer'
import Divider from '@material-ui/core/Divider'
import {BottomNavigation} from '../../components/ui/bottomNavigation/BottomNavigation'
import {BottomNavigationButton} from '../../components/ui/bottomNavigation/BottomNavigationButton'

const useStyles = makeStyles(() => ({
  finishButton: {
    background: '#ddf1e1',
    '&:hover': {
      background: '#8fd19e'
    }
  }
}))

export const getUniqueName = (offer, offerZone) => {
  const sameNames = offer.offerZones.filter(otherOfferZone => offerZone.name === otherOfferZone.name)
  const sameNameCount = sameNames.length
  const sameNameIndex = sameNames.indexOf(offerZone)
  return sameNameCount > 1 ? `${offerZone.name} ${sameNameIndex + 1}` : offerZone.name
}

export const CreateOfferAppBar = ({step, setStep, resetForm}) => {
  const classes = useStyles()
  const handleSetStep = (nextStep) => () => {
    window.scrollTo(0, 0)
    setStep(nextStep)
  }

  return (
    <AppBar>
      <Toolbar component={Box} display={'flex'} width={'100%'}>
        {step === STEPS.FORM ? (
          <>
            <Button variant={'outlined'} size={'small'} onClick={resetForm}>Tilbakestill skjema</Button>
            <IconButton className={classes.finishButton} onClick={handleSetStep(STEPS.CONFIRMATION)} style={{marginLeft: 'auto'}}>
              <CheckIcon />
            </IconButton>
          </>
        ) : (
          <IconButton onClick={handleSetStep(STEPS.FORM)}>
            <ChevronLeftIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
}

const CreateOfferBottomNavigation = ({toggleZonesDrawer}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const left = isMobile ? 0 : 200
  const right = isMobile ? 0 : 200

  const bottomButtons = [{
    name: 'Ny sone',
    icon: <ViewQuiltIcon />,
    onClick: toggleZonesDrawer
  }]

  if (!isMobile) return null

  return (
    <BottomNavigation left={left} right={right}>
      {bottomButtons.map(bottomButton => {
        return (
          <BottomNavigationButton key={bottomButton.name} label={bottomButton.name} icon={bottomButton.icon} style={bottomButton.style} onClick={bottomButton.onClick} />
        )
      })}
    </BottomNavigation>
  )
}

const OfferZone = ({offerZoneIndex, offer, setOffer}) => {
  const offerZone = offer.offerZones[offerZoneIndex]
  const [loading, setLoading] = useState(false)
  const data = useFetch(`/zones/${offerZone.zoneId}`, setLoading)
  const zone = data?.zone

  useEffect(() => {
    if (!zone) return

    const offerZonePriceFields = zone.priceFields.map(priceField => {
      const matchingOfferZonePriceField = offerZone.offerZonePriceFields.find(offerZonePriceField => offerZonePriceField.priceFieldId === priceField.id)
      return {priceFieldId: priceField.id, name: priceField.name, price: priceField.price, quantity: matchingOfferZonePriceField?.quantity || 0}
  })

    setOffer(prevOffer => {
      const newOfferZone = {...offerZone, offerZonePriceFields}
      const newOfferZones = replaceAt(prevOffer.offerZones, offerZoneIndex, newOfferZone)
      return {...prevOffer, offerZones: newOfferZones}
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone])

  const name = useMemo(() => {
    return getUniqueName(offer, offerZone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer.offerZones.length])

  if (loading) return null

  if (!zone) return <Typography style={{textAlign: 'center'}} variant={'body1'}>Finner ikke sone</Typography>

  const removeOfferZone = () => {
    setOffer(prevOffer => {
      const newOfferZones = removeAt(prevOffer.offerZones, offerZoneIndex)
      return {...prevOffer, offerZones: newOfferZones}
    })
  }

  const handleChange = (offerZonePriceFieldIndex) => (event) => {
    event.persist()

    const newValue = parseInt(event.target.value) || 0
    const newValue2 = newValue < 0 ? 0 : newValue

    setOffer(prevOffer => {
      const prevOfferZones = prevOffer.offerZones
      const prevOfferZone = prevOfferZones[offerZoneIndex]
      const newOfferZonePriceField = {...prevOfferZone.offerZonePriceFields[offerZonePriceFieldIndex], quantity: newValue2}
      const newOfferZonePriceFields = replaceAt(prevOfferZone.offerZonePriceFields, offerZonePriceFieldIndex, newOfferZonePriceField)
      const newOfferZone = {...prevOfferZone, offerZonePriceFields: newOfferZonePriceFields}
      const newOfferZones = replaceAt(prevOfferZones, offerZoneIndex, newOfferZone)
      return {...prevOffer, offerZones: newOfferZones}
    })
  }

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mb={1}>
        <Typography variant={'h3'} style={{marginBottom: 0}}>{name}</Typography>
        <IconButton size={'small'} onClick={removeOfferZone}>
          <RemoveIcon />
        </IconButton>
      </Box>
      {offerZone.offerZonePriceFields.length ? offerZone.offerZonePriceFields.map((offerZonePriceField, offerZonePriceFieldIndex) => {
        const field = {
          title: offerZonePriceField.name,
          type: 'counter'
        }

        return (
          <FormField key={offerZonePriceFieldIndex} field={field} value={offerZonePriceField.quantity} onChange={handleChange(offerZonePriceFieldIndex)} />
        )
      }) : <Typography style={{textAlign: 'center'}} variant={'body1'}>Ingen prisfelt på sone funnet</Typography>}
      <Box my={2} display={'flex'} justifyContent={'center'}>
        <Link component={Button} to={'/opprett-prisfelt?returner=/opprett-anbud'} variant={'outlined'} size={'small'} startIcon={<AddIcon />}>Opprett nytt prisfelt</Link>
      </Box>
      {offerZoneIndex !== offer.offerZones.length - 1 && <Divider style={{margin: '16px 0'}} />}
    </>
  )
}

const OfferZones = ({offer, setOffer}) => {
  return (
    <>
      <Typography style={{textAlign: 'center'}} variant={'h1'}>Opprett anbud</Typography>
      <Box display={'flex'} justifyContent={'center'}>
        <Box maxWidth={350} width={'100%'}>
          <form noValidate>
            {offer.offerZones.length === 0 && <Typography style={{textAlign: 'center'}} variant={'body1'}>Legg til sone for å sette i gang</Typography>}
            {offer.offerZones.map((offerZone, offerZoneIndex) => {
              return <OfferZone key={offerZoneIndex} offerZoneIndex={offerZoneIndex} offer={offer} setOffer={setOffer} />
            })}
          </form>
        </Box>
      </Box>
    </>
  )
}

const STEPS = {
  FORM: 0,
  CONFIRMATION: 1
}

const initialOffer = {offerZones: []}

export const CreateOffer = () => {
  const [step, setStep] = useState(STEPS.FORM)
  const [loading, setLoading] = useState(false)
  const [zonesMobileOpen, setZonesMobileOpen] = useState(false)
  const [offer, setOffer] = usePersistentState('offer', initialOffer)
  const data = useFetch('/zones', setLoading)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const zones = data && data.zones ? data.zones.map(zone => ({id: zone.id, name: zone.name, icon: <AddIcon />})).sort((zoneA, zoneB) => (zoneA.name).localeCompare(zoneB.name)) : []

  if (loading) return null

  if (!zones.length) return <Typography variant={'body1'}>Opprett soner og prisfelter først</Typography>

  const addOfferZone = (zone) => setOffer(prevOffer => {
    return {...prevOffer, offerZones: [...prevOffer.offerZones, {zoneId: zone.id, name: zone.name, offerZonePriceFields: []}]}
  })

  const resetForm = () => {
    setOffer(initialOffer)
    localStorage.removeItem('confirmOffer')
  }

  const toggleZonesDrawer = () => setZonesMobileOpen(prevMobileOpen => !prevMobileOpen)
  const closeZonesDrawer = () => setZonesMobileOpen(false)

  return (
    <>
      <CreateOfferAppBar step={step} setStep={setStep} resetForm={resetForm} />
      {step === STEPS.FORM ? (
        <Box pr={!isMobile && '200px'}>
          <OfferZones offer={offer} setOffer={setOffer} />
          <CreateOfferBottomNavigation toggleZonesDrawer={toggleZonesDrawer} />
          <OfferZoneMenu zones={zones} mobileOpen={zonesMobileOpen} toggleDrawer={toggleZonesDrawer} closeDrawer={closeZonesDrawer} addZoneItem={addOfferZone} />
        </Box>
      ) : (
        <ConfirmOffer offer={offer} resetForm={resetForm} />
      )}
    </>
  )
}
