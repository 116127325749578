import React from 'react'
import {FormControlLabel, Switch} from '@material-ui/core'

export const BooleanField = ({label, value, onChange}) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={Boolean(value)}
          onChange={onChange}
          color="primary"
        />
      }
      label={label}
    />
  )
}
