import jwtDecode from 'jwt-decode'
import {useSnackbar} from 'notistack'

export const roles = {
  admin: 2,
  owner: 1,
  user: 0
}

export const roleToTitle = (role) => {
  switch (role) {
    case 'admin':
      return 'Admin'
    case 'owner':
      return 'Eier'
    case 'user':
      return 'Bruker'
    default:
      return 'Ukjent'
  }
}

// export const useAccessToken = () => {
//   const [accessToken, setAccessToken] = useState(null)
//   const {enqueueSnackbar} = useSnackbar()
//
//   useEffect(() => {
//     const accessToken = localStorage.getItem('accessToken')
//     setAccessToken(accessToken)
//   }, [])
//
//   if (!accessToken) {
//     enqueueSnackbar('Du må være innlogget', {variant: 'error'})
//   }
//
//   return accessToken
// }
//
// export const useCurrentUser = () => {
//   const [currentUser, setCurrentUser] = useState(null)
//   const {enqueueSnackbar} = useSnackbar()
//   const accessToken = useAccessToken()
//
//   useEffect(() => {
//     const decodedAccessToken = jwtDecode(accessToken)
//     setCurrentUser(decodedAccessToken)
//   }, [])
//
//   if (!currentUser) {
//     enqueueSnackbar('Brukeren din er ugyldig', {variant: 'error'})
//     return false
//   }
//   return currentUser
// }

export const isAuthorized = (currentRole, role) => {
  if (!role) return true
  if (!currentRole) return false
  return roles[currentRole] >= roles[role]
}

export const useIsAuthorized = (role) => {
  const currentUser = useCurrentUser()
  if (!currentUser) return false
  if (!role) return true
  return roles[currentUser.role] >= roles[role]
}

export const useAccessToken = () => {
  const accessToken = localStorage.getItem('accessToken')
  return accessToken
}

export const useCurrentUser = () => {
  const accessToken = useAccessToken()
  if (!accessToken) return false

  const currentUser = jwtDecode(accessToken)
  if (!currentUser) return false

  return currentUser
}

export const useIsAuthenticated = () => {
  const currentUser = useCurrentUser()

  const now = new Date()
  const expiresAt = new Date(currentUser.exp * 1000)
  if (now > expiresAt) {
    localStorage.removeItem('accessToken')
    return false
  }

  if (!currentUser) {
    localStorage.removeItem('accessToken')
    return false
  }

  return true
}

export const useIsAdmin = () => {
  const accessToken = useAccessToken()
  if (!accessToken) return false

  const decodedAccessToken = jwtDecode(accessToken)

  return decodedAccessToken.role === 'admin';
}

export const useIsOwner = () => {
  const accessToken = useAccessToken()
  if (!accessToken) return false

  const decodedAccessToken = jwtDecode(accessToken)

  return decodedAccessToken.role === 'admin' || decodedAccessToken.role === 'owner';
}

export const useRequireAuthenticated = () => {
  const {enqueueSnackbar} = useSnackbar()

  const currentUser = useCurrentUser()

  if (!currentUser) {
    enqueueSnackbar('Autentisering feilet', {variant: 'error'})
    localStorage.removeItem('accessToken')
    return false
  }

  const now = new Date()
  const expiresAt = new Date(currentUser.exp * 1000)
  if (now > expiresAt) {
    localStorage.removeItem('accessToken')
    enqueueSnackbar('Forrige innlogging utløpt', {variant: 'error'})
    return false
  }

  if (!currentUser) {
    localStorage.removeItem('accessToken')
    enqueueSnackbar('Autentisering feilet', {variant: 'error'})
    return false
  }

  return true
}

export const useRequireAdmin = () => {
  const {enqueueSnackbar} = useSnackbar()
  const isAuthenticated = useRequireAuthenticated()

  if (!isAuthenticated) {
    return false
  }

  const accessToken = localStorage.getItem('accessToken')
  const decodedAccessToken = jwtDecode(accessToken)

  if (decodedAccessToken.role !== 'admin') {
    localStorage.removeItem('accessToken')
    enqueueSnackbar('Du må ha administratortilgang', {variant: 'error'})
    return false
  }

  return true
}
