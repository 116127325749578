import React from 'react'
import {SubmitForm} from '../../components/SubmitForm'
import {organizationFields} from './Organization'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

export const CreateOrganization = () => {
  const location = useLocation()
  const {returner: previousPath} = queryString.parse(location.search)

  return (
    <SubmitForm
      fields={organizationFields}
      title={'Opprett organisasjon'}
      apiSubmitPath={'/organizations'}
      previousPath={previousPath} />
  )
}
