import {Box, BottomNavigation as MaterialBottomNavigation, makeStyles} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  bottomNavigation: {
    borderTop: '1px solid #ddd',
    zIndex: 1
  }
}))

export const BottomNavigation = ({children, left = 0, right = 0, ...props}) => {
  const classes = useStyles()
  return (
    <MaterialBottomNavigation className={classes.bottomNavigation} showLabels component={Box} position={'fixed'} bottom={0} width={`calc(100% - ${left + right}px)`} left={left} right={right} {...props}>
      {children}
    </MaterialBottomNavigation>

  )
}